<template>

  <div class="page" style="width: 100%; margin: 0 auto;">

    <div style="text-align:center;padding:6px 0;background-color: #1283FF;">
      <el-date-picker v-model="ckdMonth" value-format="yyyy-MM" type="month" placeholder="选择月" @change="reloadData">
      </el-date-picker>

    </div>



    <el-row >
      <div style="text-align: center; padding: 10px; color:#fff; background-color: #1283FF;">
        <div>{{ ckdMonth }}主体营收表</div>
        <div style="font-size:10px">向左滑动屏幕查看详细</div>
      </div>
      <el-table :data="DataList"  stripe border size="mini" height="calc(100vh - 130px)">

        <el-table-column fixed="left" prop="username" label="业务员" width="60">
        </el-table-column>
        <el-table-column fixed="left" prop="custom_name" label="对接主体" width="80">
        </el-table-column>


        <el-table-column prop="" label="月度" width="80">
          <template slot-scope="scope">
            {{ ckdMonth.split('-')[1] }}月
          </template>
        </el-table-column>

        <el-table-column prop="now_money" label="营收达成" width="100">
          <template slot-scope="scope">
            {{parseInt((scope.row.now_money) * 100) / 100 }}
          </template>
        </el-table-column>
        <el-table-column prop="now_money" label="同比" width="80">
          <template slot-scope="scope">
            {{scope.row.tb_month}}%
          </template>
        </el-table-column>
        <el-table-column prop="now_money" label="毛利润" width="100">
          <template slot-scope="scope">
            {{parseInt((scope.row.now_money - scope.row.now_yongjin) * 100) / 100 }}
          </template>
          
        </el-table-column>
        <el-table-column prop="month_baifang" label="客户拜访次数" width="80">
        </el-table-column>
        <el-table-column prop="now_money" label="年度" width="80">
          <template slot-scope="scope">
            {{ ckdMonth.split('-')[0] }}
          </template>
          
        </el-table-column>
        <el-table-column prop="now_year_money" label="营收达成" width="100">
         
          <template slot-scope="scope">
            {{parseInt((scope.row.now_year_money) * 100) / 100 }}
          </template>
        </el-table-column>
        <el-table-column prop="now_money" label="同比" width="80">
          <template slot-scope="scope">
            {{scope.row.tb_year}}%
          </template>
        </el-table-column>
        <el-table-column prop="now_money" label="毛利润" width="100">
          <template slot-scope="scope">

            {{parseInt((scope.row.now_year_money - scope.row.now_year_yongjin) * 100) / 100 }}
          </template>
        </el-table-column>
        <el-table-column prop="now_money" label="目标达成率" width="100">
          <template slot-scope="scope">
           -
          </template>
        </el-table-column>
        <el-table-column prop="now_money" label="客户拜访次数" width="80">
          <template slot-scope="scope">
            {{scope.row.year_baifang}}
          </template>
        </el-table-column>

        



      </el-table>
    </el-row>

  </div>
</template>

<script>
export default {
  data() {
    return {
      ckdMonth: "",
      DataList: []
    }
  },
  mounted() {
    document.title = "主体报表"
    let year = new Date().getFullYear()
    let month = new Date().getMonth()+1
   
    if (month < 10) {
      month = "0" + month
    }
    this.ckdMonth = year + "-" + month
    this.reloadData()
  },
  methods: {
    reloadData() {
      this.getData()

    },
    getData() {

      this.$http.post("/api/mubiao_list", {
        year: parseInt(this.ckdMonth.split("-")[0]),
        month: parseInt(this.ckdMonth.split("-")[1])
      }).then(res => {

        let heji = {
          last1_money: 0,
          last1_yongjin: 0,
          last_year_money: 0,
          last_year_yongjin: 0,
          month_baifang: 0,
          now_money: 0,
          now_year_money: 0,
          now_year_yongjin: 0,
          now_yongjin: 0,
          year_baifang: 0,
          tb_month: 0,
          tb_year: 0
        }
        //计算环比
        for (let item of res.data) {
          item.tb_month = 0;
          item.tb_year = 0;

          if (item.last1_money) {
            item.tb_month = Math.round(item.last1_money / (item.now_money - item.last1_money) * 10000) / 100
          } else {
            item.tb_month = 100
          }

          if (item.last_year_money) {
            item.tb_year = Math.round(item.last_year_money / (item.now_year_money - item.last_year_money) * 10000) / 100
          } else {
            item.tb_year = 100
          }

          for (let k in heji) {
            if (item[k]) {
              heji[k] += item[k]
            }
          }


        }

        if (heji.last1_money) {
          heji.tb_month = Math.round(heji.last1_money / (heji.now_money - heji.last1_money) * 10000) / 100
        } else {
          heji.tb_month = 100
        }

        if (heji.last_year_money) {
          heji.tb_year = Math.round(heji.last_year_money / (heji.now_year_money - heji.last_year_money) * 10000) / 100
        } else {
          heji.tb_year = 100
        }
        heji.custom_name="合计"
        




        this.DataList =[heji].concat( res.data)

       
      })
    }

  }
}
</script>

<style scoped>

</style>
